// This code was originally migrated from Map
.Map {
    .gm-style .gm-style-iw-c {
        padding: 0;
        box-shadow: none;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
        border-radius: 2px;
    }

    .gm-style-iw-t:after {
        content: initial;
    }

    .gm-ui-hover-effect {
        background-color: #fff !important;
        border-radius: 50% !important;
        top: 4px !important;
        right: 4px !important;
        opacity: 1 !important;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    }

    .gm-style .gm-style-iw-d {
        overflow: hidden !important;

        & + button {
            display: none !important;
        }
    }
}

// This code was originally migrated from MapFilter
.MapFilter {
    .gm-style .gm-style-iw-c {
        padding: 0 !important;
        box-shadow: none;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
        border-radius: 2px;
    }

    .gm-style-iw .gm-style-iw-c {
        padding: 0 !important;
    }

    .gm-style .gm-style-iw-tc {
        top: -1px;
    }

    .gm-style-iw-t:after {
        content: initial;
    }

    .gm-ui-hover-effect {
        background-color: #fff !important;
        border-radius: 50% !important;
        top: 4px !important;
        right: 4px !important;
        z-index: 2 !important;
        opacity: 1 !important;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    }

    .gm-style .gm-style-iw-d {
        overflow: hidden !important;
    }
}
